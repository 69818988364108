// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const icon = (name) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Ana Sayfa',
    path: '/dashboard/app',
    icon: icon('ep:menu'),
    permissions: [
      'false:false',
      'user:false',
      'user:operations',
      'user:manager',
      'esnaf:false',
      'esnaf:operations',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'admin:EPS',
      'superadmin:manager',
      'superadmin:operations',
    ],
    category: 'Yönetim',
  },
  {
    title: 'Tüm Gönderiler',
    path: '/dashboard/all-posts',
    icon: icon('ri:file-list-2-line'),
    permissions: [
      'false:false',
      'user:false',
      'user:operations',
      'user:manager',
      'esnaf:false',
      'esnaf:operations',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'admin:EPS',
      'superadmin:manager',
      'superadmin:operations',
    ],
    category: 'Yönetim',
  },
  {
    title: 'EP App Gönderileri',
    path: '/dashboard/ep-app-posts',
    icon: icon('ri:file-list-2-line'),
    permissions: [
      'user:false',
      'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'superadmin:manager',
      'superadmin:operations',
    ],
    category: 'Yönetim',
  },
  {
    title: 'Kargo Partner İşlemleri',
    path: '/dashboard/cargo-partner-operations',
    icon: icon('la:truck'),
    permissions: [
      'false:false',
      'user:false',
      'user:operations',
      'user:manager',
      'esnaf:false',
      'esnaf:operations',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'admin:EPS',
      'superadmin:manager',
      'superadmin:operations',
    ],
    category: 'Yönetim',
  },
  {
    title: 'Operasyon Kullanıcı Listesi',
    path: '/dashboard/users',
    icon: icon('ph:users'),
    permissions: [
      // 'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'admin:EPS',
      'superadmin:manager',
      'superadmin:operations',
      // 'false:operations',
      // 'false:manager',
    ],
    category: 'Yönetim',
  },
  {
    title: 'Noktalar',
    path: '/dashboard/points',
    icon: icon('ph:map-pin-bold'),
    permissions: [
      // 'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'admin:EPS',
      'superadmin:manager',
      'superadmin:operations',
      // 'false:operations',
      // 'false:manager',
    ],
    category: 'Yönetim',
  },
  // {
  //   title: 'Emanet Dolap Raporu',
  //   path: '/dashboard/monthly-cabine',
  //   icon: icon('mdi:report-box-outline'),
  //   permissions: ['user:manager'],
  // },
  {
    title: 'Vestiyer Listesi',
    path: '/dashboard/checkroom',
    icon: icon('ri:file-list-2-line'),
    permissions: [
      // 'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'superadmin:manager',
      'superadmin:operations',
      // 'user:false',
      // 'false:manager',
    ],
    category: 'Yönetim',
  },
  // {
  //   title: 'Dolap Takip Sistemi',
  //   path: '/dashboard/locker-track-system',
  //   icon: icon('ri:file-list-2-line'),
  //   permissions: [
  //     'highadmin:manager',
  //     'highadmin:operations',
  //     // 'false:manager',
  //   ],
  //   category: 'Yönetim',
  // },
  // {
  //   title: 'Dolap Takip Sistemi',
  //   path: '/dashboard/locker-tracking-system',
  //   icon: icon('ri:file-list-2-line'),
  //   permissions: [
  //     'highadmin:manager',
  //     'highadmin:operations',
  //     // 'false:manager',
  //   ],
  //   category: 'Yönetim',
  // },
  {
    title: 'AVM Listesi',
    path: '/dashboard/mall-list',
    icon: icon('ri:file-list-2-line'),
    permissions: ['user:manager'],
    category: 'Yönetim',
  },
  {
    title: 'Saha Raporu',
    path: '/dashboard/fast-report',
    icon: icon('teenyicons:clipboard-plus-outline'),
    permissions: [
      'user:operations',
      'user:manager',
      'false:false',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'superadmin:manager',
      'superadmin:operations',
      // 'esnaf:false',
      // 'esnaf:operations',
    ],
    category: 'Yönetim',
  },

  {
    title: 'Rapor Oluştur',
    path: '/dashboard/create-report',
    icon: icon('lets-icons:file-dock-add'),
    permissions: [
      // 'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'admin:EPS',
      'superadmin:manager',
      'superadmin:operations',
      // 'user:false',
      // 'false:false',
    ],
    category: 'Yönetim',
  },


  {
    title: 'Log Arama',
    path: '/dashboard/log-search',
    icon: icon('et:search'),
    permissions: [
      'highadmin:manager',
      'highadmin:operations',
      'superadmin:manager',
      'superadmin:operations',
    ],
    category: 'Yönetim',
  },
  {
    title: 'Bol Para',
    path: '/dashboard/bol-para',
    icon: icon('mdi:hand-coin-outline'),
    permissions: [
      // 'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'superadmin:manager',
      'superadmin:operations',
      // 'user:false',
      // 'false:false',
      // 'esnaf:false',
      // 'esnaf:operations',
    ],
    category: 'Bol Para',
  },
  {
    title: 'Bol Para Başvurular',
    path: '/dashboard/bol-para-applications',
    icon: icon('uil:file-check-alt'),
    permissions: [
      'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'superadmin:manager',
      'superadmin:operations',
      // 'user:false',
      // 'false:false',
      // 'esnaf:false',
      // 'esnaf:operations',
    ],
    category: 'Bol Para',
  },
  {
    title: 'Kampanyalar',
    path: '/dashboard/campaign',
    icon: icon('mage:gift'),
    permissions: [
      // 'user:operations',
      'user:manager',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'superadmin:manager',
      'superadmin:operations',
      // 'user:false',
      // 'false:false',
      // 'esnaf:false',
      // 'esnaf:operations',
    ],
    category: 'Bol Para',
  },
  {
    title: 'Teknik Destek Talepleri',
    path: '/dashboard/support-tickets',
    icon: icon('hugeicons:customer-support'),
    permissions: [
      'user:operations',
      'user:manager',
      'user:false',
      'false:false',
      'esnaf:false',
      'esnaf:operations',
      'highadmin:manager',
      'highadmin:operations',
      'admin:manager',
      'admin:operations',
      'admin:EP',
      'admin:EPS',
      'superadmin:manager',
      'superadmin:operations',
    ],
    category: 'Destek',
  },
];

export default navConfig;
