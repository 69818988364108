import React, { useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Card,
  CircularProgress,
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  Stack,
  Button,
  InputLabel,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import DynamicTable from 'src/components/shared/DynamicTable';
import Services from 'src/services/Services';
import { useLocalization } from 'src/context/LocalizationContext';
import moment from 'moment';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import CustomFilter from 'src/components/filter/CustomFilter';
import Iconify from 'src/components/iconify';
import zIndex from '@mui/material/styles/zIndex';

export default function LockerTrackingSystem() {
  const { language } = useLocalization();
  const parsedUser = JSON.parse(localStorage.getItem('user'));

  const [loading, setLoading] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [lockers, setLockers] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLocker, setSelectedLocker] = useState(null);

  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const handleOpenDialog = (locker) => {
    setSelectedLocker(locker);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // setSelectedLocker(null);
  };

  const handleOpenPasswordDialog = () => {
    setOpenPasswordDialog(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
  };

  const handlePasswordRequest = (row) => {
    if (row) {
      setSelectedLocker(row);
    }

    handleCloseDialog();
    handleOpenPasswordDialog();
  };

  const getStatusStyle = (status) => {
    if (status === 'Empty') {
      return {
        backgroundColor: '#ECFDF3',
        color: '#067647',
        border: '1px solid #ABEFC6',
        borderRadius: '12px',
        display: 'inline-flex',
        alignItems: 'center',
        padding: '4px 8px',
      };
    } else {
      return {
        backgroundColor: '#FEF3F2',
        color: '#B42318',
        border: '1px solid #FECDCA',
        borderRadius: '12px',
        display: 'inline-flex',
        alignItems: 'center',
        padding: '4px 8px',
      };
    }
  };

  const getLockerNumberStyle = (status) => {
    return {
      backgroundColor: '#F9FAFB',
      color: status === 'Empty' ? '#067647' : '#B42318',
      border: '1px solid #EAECF0',
      borderRadius: '12px',
      display: 'inline-flex',
      alignItems: 'center',
      padding: '4px 8px',
      gap: '8px',
    };
  };

  const getLockIconStyle = {
    width: '16px',
    height: '16px',
    color: '#A5A4A4',
  };

  let TABLE_HEAD = [
    {
      id: 'lockerNumber',
      label: language.commons.labels.locker_no,
      format: (value, row) => (
        <div style={getLockerNumberStyle(row.statusName)}>
          <Iconify icon="mdi:lock" style={getLockIconStyle} />
          <span>{value}</span>
        </div>
      ),
    },
    {
      id: 'startDate',
      label: language.commons.labels.start_date,
      format: (value) => formatDate(value),
    },
    {
      id: 'endDate',
      label: language.commons.labels.end_date,
      format: (value) => formatDate(value),
    },
    {
      id: 'statusName',
      label: language.commons.status,
      format: (value) => (
        <div style={getStatusStyle(value)}>
          <span
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: value === 'Empty' ? '#17B26A' : '#F04438',
              borderRadius: '50%',
              marginRight: '8px',
            }}
          />
          {value === 'Empty' ? 'Boşta' : 'Dolu'}
        </div>
      ),
    },
    {
      id: 'pointTitle',
      label: language.commons.get_password,
      alignRight: false,
      format: (value, row) => (
        <Typography onClick={() => handlePasswordRequest(row)}>
          Şifre Al
        </Typography>
      ),
    },
    {
      id: 'action',
      label: language.commons.column.details,
      alignRight: false,
      format: (value, row) => (
        <Tooltip title={language.commons.detail}>
          <Iconify
            style={{
              color: '#98A2B3',
              width: 22,
              height: 22,
              cursor: 'pointer',
            }}
            icon="bx:detail"
            onClick={() => handleOpenDialog(row)}
          />
        </Tooltip>
      ),
    },
  ];

  const calculateUsageTime = (start, end) => {
    const startMoment = moment(start).local();
    const endMoment = moment(end).local();
    const duration = moment.duration(endMoment.diff(startMoment));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let usageTime = '';
    if (days > 0) {
      usageTime += `${days} gün `;
    }
    if (hours > 0 || days > 0) {
      usageTime += `${hours} saat `;
    }
    usageTime += `${minutes} dakika`;

    return usageTime.trim();
  };

  const formatDate = (dateString) => {
    const formattedDate = moment(dateString)
      .local()
      .format('DD.MM.YYYY | HH:mm');
    return formattedDate;
  };

  const formatGSM = (gsm) => {
    if (!gsm || gsm.length < 5) return gsm;
    const firstThree = gsm.slice(0, 3);
    const lastTwo = gsm.slice(-2);
    const hiddenPart = '*'.repeat(gsm.length - 5);
    return `${firstThree}${hiddenPart}${lastTwo}`;
  };

  const getLockers = (code) => {
    setLoading(true);
    Services.GetLockers(code)
      .then((res) => {
        console.log(res.data);
        setLockers(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLockers([]);
      })
      .finally(() => setLoading(false));
  };

  const getLockerLocations = () => {
    setLoading(true);
    Services.GetLockerLocations()
      .then((res) => {
        console.log(res);
        const formattedData = res.data.map((item) => ({
          value: item.locationCode,
          name: item.name,
        }));
        setLocations(formattedData);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getLockerLocations();
  }, []);

  return (
    <>
      <Helmet>
        <title>{language.locker_tracking_system.titles.site_title}</title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.locker_tracking_system.titles.title}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
          paddingX={2}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Card>
          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            <>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                gap="15px"
              >
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <InputLabel id="type-filter-label">Lokasyon</InputLabel>
                  <Select
                    labelId="type-filter-label"
                    value={selectedLocation ? selectedLocation?.name : ''}
                    placeholder="Lokasyon"
                    onChange={(e) => {
                      const selected = locations?.find(
                        (location) => location.name === e.target.value
                      );
                      console.log(selected);
                      getLockers(selected.value);
                      setSelectedLocation(selected);
                    }}
                  >
                    {locations?.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </>
          </CustomFilter>
          {loading ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: 2, height: '100%' }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <DynamicTable
              data={lockers}
              headers={TABLE_HEAD}
              isOrdered={false}
              isMobile={isMobile}
              handleModal={handleModal}
              handleCloseModal={handleCloseModal}
              initialOrder={'createdDateTime'}
              initalOrderDirection={'desc'}
            />
          )}
        </Card>
      </Stack>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {language.locker_tracking_system.titles.title}
        </DialogTitle>
        <DialogContent>
          {selectedLocker && (
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="text.secondary">
                {selectedLocation?.name || 'Seçili AVM Adı'}
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" fontWeight="bold">
                  Dolap No
                </Typography>
                <Typography
                  variant="body1"
                  color={
                    selectedLocker.statusName === 'Empty'
                      ? '#067647'
                      : '#B42318'
                  }
                >
                  {selectedLocker.lockerNumber}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">Başlangıç Tarihi</Typography>
                <Typography variant="body1">
                  {formatDate(selectedLocker.startDate)}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">Bitiş Tarihi</Typography>
                <Typography variant="body1">
                  {formatDate(selectedLocker.endDate)}
                </Typography>
              </Stack>
              {/* <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">Kullanıcı Ad Soyad</Typography>
                <Typography variant="body1">
                  {selectedLocker.userName}
                </Typography>
              </Stack> */}
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">Telefon No</Typography>
                <Typography variant="body1">
                  {' '}
                  {selectedLocker.gsm ? formatGSM(selectedLocker.gsm) : '-'}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">Kullanım Süresi</Typography>
                <Typography variant="body1">
                  {selectedLocker.startDate && selectedLocker.endDate
                    ? calculateUsageTime(
                        selectedLocker.startDate,
                        selectedLocker.endDate
                      )
                    : '-'}
                </Typography>
              </Stack>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  handlePasswordRequest();
                }}
              >
                Şifre Al
              </Button>
            </Stack>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={openPasswordDialog}
        onClose={handleClosePasswordDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Stack>
            {/* Kilit Tasarımı */}
            <Button
              onClick={handleClosePasswordDialog}
              style={{
                position: 'absolute',
                top: '8px',
                right: '4px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <Iconify
                icon="mdi:close"
                style={{ fontSize: '20px', color: '#929191' }}
              />
            </Button>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '120px',
                height: '120px',
              }}
            >
              {/* Dış Çizgiler */}
              <div
                style={{
                  position: 'absolute',
                  width: '120px',
                  height: '120px',
                  borderRadius: '50%',
                  border: '1px solid rgba(234, 236, 240, 0.7)', // İlk halka
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  width: '180px',
                  height: '180px',
                  borderRadius: '50%',
                  border: '1px solid rgba(234, 236, 240, 0.5)', // İkinci halka
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  width: '240px',
                  height: '240px',
                  borderRadius: '50%',
                  border: '1px solid rgba(234, 236, 240, 0.3)', // Üçüncü halka
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  width: '300px',
                  height: '300px',
                  borderRadius: '50%',
                  border: '1px solid rgba(234, 236, 240, 0.1)', // Dördüncü halka
                }}
              />
              {/* İç Çizgiler ve Kilit */}
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '80px',
                  height: '80px',
                  background: '#ECFDF3',
                  borderRadius: '50%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '65px',
                    height: '65px',
                    background: '#ECFDF3',
                    borderRadius: '50%',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    width: '50px',
                    height: '50px',
                    background: '#DCFAE6',
                    borderRadius: '50%',
                  }}
                />
                <Iconify
                  icon="mdi:lock"
                  style={{
                    color: '#929191',
                    width: '28px',
                    height: '28px',
                    zIndex: 999,
                  }}
                />
              </div>
            </div>
          </Stack>
        </DialogTitle>
        <DialogContent
          style={{
            zIndex: 1000,
          }}
        >
          <Stack spacing={3}>
            <Typography variant="h6" fontWeight="bold">
              Şifre Alma Talebi
            </Typography>
            <Typography variant="body1">
              <strong>{`"${selectedLocker?.lockerNumber}"`}</strong> Nolu dolaba
              ait işlem için şifre almak istediğinize emin misiniz?
            </Typography>
            {/* İptal ve Devam Et Butonları */}
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="outlined"
                onClick={handleClosePasswordDialog}
                style={{
                  borderColor: '#EAECF0',
                  color: '#929191',
                  width: '100px',
                }}
              >
                İptal Et
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  // Şifre alma işlemi burada yapılabilir
                  handleClosePasswordDialog();
                }}
                style={{
                  backgroundColor: '#D92D20',
                  color: '#FFFFFF',
                  width: '100px',
                }}
              >
                Devam Et
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
