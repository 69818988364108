import React, { useState } from 'react';
import { Box, IconButton, Stack, Typography, } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Button from 'src/theme/overrides/Button';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



const questionTypeLabels = {
    short_answer: 'Açık Uçlu Soru',
    rating: 'Derecelendirme',
    multi_choice: 'Çoktan Seçmeli',
};


const DraggableItem = ({ item, index, onDragStart, onDragOver, onDrop, isDragging, isOver, removeQuestion, handleEditeQuestion }) => {

    console.log("item", item);
    const questionTypeLabel = questionTypeLabels[item.question_type] || item?.question_type;


    return (
        <Box
            sx={{
                opacity: isDragging ? 0.5 : 1,
                border: isDragging ? '2px dashed blue' : 'none',
                backgroundColor: isOver ? 'rgb(255, 240, 239)' : 'white',
                cursor: 'move',
                marginBottom: '20px'
            }}
            draggable
            onDragStart={() => onDragStart(index)}
            onDragOver={(e) => {
                e.preventDefault();
                onDragOver();
            }}
            onDrop={onDrop}
        >
            <Stack flexDirection="row" alignItems="center" sx={{ minHeight: '120px', border: '1px solid #f1dbdb', borderRadius: '6px', position: 'relative' }}>


                <Stack flex={1}>
                    <IconButton
                        onClick={() => handleEditeQuestion(index)}
                        sx={{
                            minHeight: '120px',
                            borderRight: '1px solid #f1dbdb',
                            borderRadius: '0',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                            },
                        }}>
                        <DragIndicatorIcon />
                    </IconButton>
                </Stack>
                <Stack flex={8} >
                    <Stack sx={{ color: 'text.primary', minHeight: '120px', display: 'flex', padding: '15px', gap: '15px' }} variant="h6">
                        <Stack flexDirection="row" alignItems="center" sx={{ gap: '5px' }}>
                            <Typography sx={{ color: 'black' }} variant="h6">
                                Soru:
                            </Typography>

                            <div dangerouslySetInnerHTML={{ __html: item.label }} />
                        </Stack>
                        {
                            item.question_type === 'rating' ? (
                                item?.question_options?.map((e, index) => (
                                    <Stack key={index}>
                                        <Typography sx={{ color: 'text.primary' }} variant="h6">
                                            Seçenekler:
                                        </Typography>
                                        <div>{e?.minValue + '.' + e?.minContent}</div>
                                        <div>...</div>
                                        <div>{e?.maxValue + '.' + e?.maxContent}</div>


                                        <br />
                                    </Stack>
                                ))
                            ) : (
                                item.question_options.map((e, index) => (
                                    <Stack key={index} flexDirection="row">
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {index + 1 + '.'}
                                        </Typography>
                                        <Typography>
                                            {e}
                                        </Typography>

                                    </Stack>
                                ))
                            )

                        }

                        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" marginTop={4}>
                            {item.multi_choice && (
                                <Stack alignItems="center" justifyContent="center" flexDirection="row" sx={{ color: '#E44040', fontWeight: 'bold', textAlign: 'left', gap: '5px' }} variant="body1">
                                    <InfoOutlinedIcon sx={{ fontSize: '20px', color: '#E44040', verticalAlign: 'middle' }} />
                                    <Typography>Birden fazla seçenek seçebilir mi</Typography>
                                </Stack>
                            )}
                            {!item.multi_choice && <span style={{ flexGrow: 1 }}></span>}

                            <Typography sx={{ color: '#E44040', textAlign: 'right',fontSize:'13px'}} >
                                {questionTypeLabel}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: '10' }}>
                    <IconButton onClick={() => removeQuestion(index)}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Box >
    );
};



export default DraggableItem;
