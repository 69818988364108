import React, { useEffect, useState } from 'react';
import {
    Card,
    Stack,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Services from 'src/services/Services';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { toast } from 'react-toastify';
import moment from 'moment';
import DynamicTable from 'src/components/shared/DynamicTable';
import FilterButton from 'src/components/shared/FilterButton';
import ClearButton from 'src/components/shared/ClearButton';

export default function LogSearch() {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const [isBackdropOpen, setIsBackdropOpen] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [barcode, setBarcode] = useState('');
    const [keyword, setKeyword] = useState('');
    const [logTypes, setLogTypes] = useState([]);
    const [logType, setLogType] = useState('');
    const [logs, setLogs] = useState([]);

    const formatValue = (value) => {
        if (value === null || value === undefined || value === '') {
            return 'null';
        } else if (value === false) {
            return 'false';
        }
        return value;
    };

    const TABLE_HEAD = [
        { id: 'id', label: "ID", format: formatValue, alignRight: false },
        { id: 'barcode', label: "Barkod", format: formatValue, alignRight: false },
        {
            id: 'log',
            label: "Log",
            format: (value) => formatValue(JSON.stringify(value)),
            alignRight: false
        },
        { id: 'action', label: "Log Türü", format: formatValue, alignRight: false },
        {
            id: 'createdBy',
            label: "Oluşturan",
            format: (value) => {
                if (value === null || value === undefined) return 'null';
                return formatValue(value.name) + " " + formatValue(value.lastname);
            },
            sortBy:"createdBy",
            alignRight: false
        },
        {
            id: 'createdDateTime',
            label: "Oluşturulma Tarihi",
            format: (value) => {
                return value ? convertTimestampToDate(value) : 'null';
            },
            alignRight: false
        },
        { id: 'url', label: "URL", format: formatValue, alignRight: false },
        {
            id: 'post',
            label: "Post",
            format: (value) => formatValue(JSON.stringify(value)),
            alignRight: false
        },
        { id: 'userType', label: "User Type", format: formatValue, alignRight: false },
        { id: 'status', label: "Durum", format: formatValue, alignRight: false },
    ];


    const convertTimestampToDate = (timestamp) => {
        // Timestamp saniye cinsindeyse milisaniyeye çevirmek için 1000 ile çarpıyoruz
        const date = new Date(timestamp * 1000);

        // Gün, Ay ve Yılı alıyoruz
        const day = String(date.getDate()).padStart(2, "0"); // Gün (2 haneli)
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay (2 haneli, 0-11 olduğu için +1)
        const year = date.getFullYear(); // Yıl

        // Formatı birleştiriyoruz
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        setIsBackdropOpen(true)
        Services.GetPostLogsTypes(parsedUser.token)
            .then((res) => {

                if (res.data.status) {
                    setLogTypes(res.data.result)
                } else {
                    toast.error("Log türleri çekilirkenbir hata oluştu.")
                }
                setIsBackdropOpen(false)
            })
            .catch((err) => { console.log(err); setIsBackdropOpen(false); });
    }, []);

    const handleBarcodeChange = (event) => {
        setBarcode(event.target.value);
    };

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value);
    };

    const handleLogTypeChange = (event) => {
        setLogType(event.target.value);
    };

    const handleSubmit = (event) => {
        setIsBackdropOpen(true)

        const formattedStartDate = startDate ? moment(startDate).format('DD-MM-YYYY') : '';
        const formattedEndDate = endDate ? moment(endDate).format('DD-MM-YYYY') : '';

        Services.GetPostLogsSearch(parsedUser.token, barcode, logType, "", formattedStartDate, formattedEndDate, keyword)
            .then((res) => {

                if (res.data.status) {
                    setLogs(res.data.result)
                    console.log(res.data.result, "Logs");
                } else {
                    toast.error("Filtrelemeye göre log bulunamadı.")
                }
                setIsBackdropOpen(false)
            })
            .catch((err) => { toast.error("Bir hata oluştu.", err); setIsBackdropOpen(false) });
    };

    const handleClear = () =>{
        setStartDate(null)
        setEndDate(null)
        setBarcode('')
        setKeyword('')
        setLogType('')
        setLogs([])
    }

    return (
        <>
            <Helmet>
                <title>Log Arama | Easy Point Panel</title>
            </Helmet>
            <Stack style={{ paddingInline: '25px' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                    open={isBackdropOpen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Log Arama
                    </Typography>
                </Stack>
                <Card style={{ padding: '25px' }}>
                        <Stack direction="row" spacing={2} alignItems={'center'}>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Başlangıç Tarihi"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    maxDate={endDate}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Bitiş Tarihi"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    minDate={startDate}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>

                            <FormControl sx={{ minWidth: '250px' }}>
                                <InputLabel id="log-type-label">Log Türü</InputLabel>
                                <Select
                                    labelId="log-type-label"
                                    id="log-type"
                                    value={logType}
                                    label="Log Türü"
                                    onChange={handleLogTypeChange}
                                >
                                    {logTypes.map((option) => (
                                        <MenuItem key={option.type} value={option.type}>
                                            {option.type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                id="barcode"
                                label="Barkod"
                                value={barcode}
                                onChange={handleBarcodeChange}
                            />
                            <TextField
                                id="keyword"
                                label="Anahtar Kelime"
                                value={keyword}
                                onChange={handleKeywordChange}
                            />
                        
                            <FilterButton onClick={handleSubmit} label={"ARA"}/>
                            <ClearButton onClick={handleClear}/>
                        </Stack>


                    <DynamicTable
                        data={logs}
                        headers={TABLE_HEAD}
                        initialOrder={'createdDateTime'}
                        initalOrderDirection={'desc'}
                        isOrdered={true}
                    />
                </Card>
            </Stack>
        </>
    );
}