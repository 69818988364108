import { useState, useEffect, useRef } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';
// @mui
import {
  Button,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  OutlinedInput,
  Radio,
  RadioGroup,
  FormControl,
  Autocomplete,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Typography,
  Switch,
  Stack,
} from '@mui/material';
import Services from 'src/services/Services';
import { useLocalization } from 'src/context/LocalizationContext';

function AddEditUserModal({
  userID,
  isUserDialogOpen,
  actionType,
  setUserDialogOpen,
  MenuProps,
}) {


  const { language } = useLocalization();
  const [token, setToken] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(true);
  const [selectedUserType, setSelectedUserType] = useState('user');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(false);
  const [frontAuth, setFrontAuth] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [firstResponsibleOffice, setFirstResponsibleOffice] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [statusChanged, setStatusChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const parsedUser = JSON.parse(localStorage.getItem('user'));

  const roles = [
    {
      title: language.commons.labels.operation,
      value: 'operations',
    },
    {
      title: language.commons.labels.manager,
      value: 'manager',
    },
    {
      title: language.commons.labels.technical_support,
      value: 'technical-support-staff',
    },
    {
      title: language.commons.labels.technical_manager,
      value: 'technical-support-manager',
    },
  ];

  const options = locations?.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleSubmit = () => {
    setButtonLoading(true);
    setIsLoading(true)
    let allowedPoints = [];
    let body_data = {};
    if (actionType === 'new') {
      // yeni kullanıcı
      if (isAutocompleteVisible) {
        if (
          firstResponsibleOffice &&
          name?.length > 0 &&
          lastname?.length > 0 &&
          username?.length > 0 &&
          phone?.length >= 10 &&
          email?.length > 0 &&
          password?.length > 0 &&
          selectedRoles?.length > 0
        ) {
          allowedPoints.push({
            id: firstResponsibleOffice.id,
            isFirstResponsible: true,
          });
          if (selectedLocations?.length > 0) {
            for (let i = 0; i < selectedLocations?.length; i++) {
              allowedPoints.push({
                id: selectedLocations[i].id,
                isFirstResponsible: false,
              });
            }
          }

          body_data = {
            type: selectedUserType,
            name: name,
            lastname: lastname,
            email: email,
            phone: phone,
            username: username,
            password: password,
            allowedPoints: allowedPoints,
            isAdministrator: selectedRoles.includes('manager') ? true : false,
            frontAuth:
              selectedUserType === 'esnaf' ? ['operations'] : selectedRoles,
          };
        } else {
          toast.error('Zorunlu alanları doldurunuz.');
          setButtonLoading(false);
          return;
        }
      } else {
        // user ve manager
        body_data = {
          type: selectedUserType,
          name: name,
          lastname: lastname,
          email: email,
          phone: phone,
          username: username,
          password: password,
          allowedPoints: [],
          isAdministrator: selectedRoles.includes('manager') ? true : false,
          frontAuth: selectedRoles,
        };
      }
      // Servise bağlanacak
      Services.CreateEmployee(token, body_data)
        .then((res) => {
          if (res.data.status) {
            toast.success('Kullanıcı oluşturuldu.');
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          } else {
            toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
          }
        })
        .catch((err) => {
          toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
        })
        .finally(() => setButtonLoading(false));
    } else {
      // düzenleme
      if (isAutocompleteVisible) {
        if (
          firstResponsibleOffice &&
          name?.length > 0 &&
          lastname?.length > 0 &&
          username?.length > 0 &&
          phone?.length >= 10 &&
          email?.length > 0 &&
          password?.length > 0 &&
          selectedRoles?.length > 0
        ) {
          allowedPoints.push({
            id: firstResponsibleOffice.id,
            isFirstResponsible: true,
          });
          if (selectedLocations?.length > 0) {
            for (let i = 0; i < selectedLocations?.length; i++) {
              allowedPoints.push({
                id: selectedLocations[i].id,
                isFirstResponsible: false,
              });
            }
          }

          body_data = {
            userID: userID,
            type: selectedUserType,
            name: name,
            lastname: lastname,
            email: email,
            phone: phone,
            username: username,
            password: password,
            allowedPoints: allowedPoints,
            isAdministrator: selectedRoles.includes('manager') ? true : false,
            frontAuth:
              selectedUserType === 'esnaf' ? ['operations'] : selectedRoles,
            status: status,
          };
        } else {
          toast.error('Zorunlu alanları doldurunuz.');
          setButtonLoading(false);
          return;
        }
      } else {
        // user ve manager
        body_data = {
          userID: userID,
          type: selectedUserType,
          name: name,
          lastname: lastname,
          email: email,
          phone: phone,
          username: username,
          password: password,
          allowedPoints: [],
          isAdministrator: selectedRoles.includes('manager') ? true : false,
          frontAuth: selectedRoles,
          status: status,
        };
      }
      Services.UpdateProfile(token, body_data)
        .then((res) => {
          if (res.data.status) {
            userSetStatus();
          } else {
            toast.error('Kullanıcı bilgileri düzenlenirken bir hata oluştu.');
          }
        })
        .catch((err) => {
          toast.error('Kullanıcı bilgileri düzenlenirken bir hata oluştu.');
        })
        .finally(() => setButtonLoading(false));
    }
  };

  // const handleCopyPassword = () => {
  //   navigator.clipboard
  //     .writeText(password)
  //     .then(() => { })
  //     .catch((err) => {
  //       console.error('Şifre kopyalanırken bir hata oluştu:', err);
  //     });
  // };

  const handleResetPassword = () => {
    setResetLoading(true);
    api
      .post('flow/user-forced-reset-password', {
        // email: '',
        userID: userID,
      })
      .then((response) => {
        if (response.data) {
          setPassword(response.data.result);
          setPasswordDialog(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setResetLoading(false));
  };

  const handleCloseModal = () => {
    setName('');
    setLastname('');
    setUsername('');
    setEmail('');
    setPhone('');
    setPassword('');
    setSelectedRoles([]);
    setSelectedLocations([]);
    setFirstResponsibleOffice(null);
    setSelectedUserType('user');
    setUserDialogOpen(false);
  };

  const generateRandomPassword = () => {
    const length = 6;
    const charset = '0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    setPassword(password);
  };

  const userSetStatus = () => {
    if (status === false) {
      Services.UserSetPassive(token, userID)
        .then((res) => {
          if (res.data.status) {
            toast.success('Kullanıcı bilgileri düzenlendi.');
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          } else {
            toast.error('Kullanıcı bilgileri düzenlenirken bir hata oluştu.');
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      Services.UserSetActive(token, userID)
        .then((res) => {
          if (res.data.status) {
            toast.success('Kullanıcı bilgileri düzenlendi.');
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          } else {
            toast.error('Kullanıcı bilgileri düzenlenirken bir hata oluştu.');
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!isUserDialogOpen) {
      return;
    }

    if(actionType==="edit"){
      setIsLoading(true)
    }

    setToken(parsedUser.token);
    Services.GetPoints(parsedUser.token)
      .then((res) => {
        const points = res.data.result?.map((item) => ({
          title: item.title,
          id: item.id,
        }));
        setLocations(points);

        if (actionType === 'edit') {
          Services.GetUser(parsedUser.token, userID)
            .then((response) => {
              const user = response.data.result;
              const selectedRolesFromFrontAuth = roles
                .filter((role) => user?.frontAuth?.includes(role.value))
                .map((role) => role.value);

              // allowedPoints varsa ve array ise işleme devam ederiz
              const _firstResponsibleOffice = Array.isArray(user?.allowedPoints)
                ? user?.allowedPoints?.find(
                  (point) => point?.firstResponsible === true
                )
                : null;

              const _selectedLocations = Array.isArray(user?.allowedPoints)
                ? user?.allowedPoints
                  ?.filter((point) => point?.firstResponsible === false)
                  .map((point) => point)
                : [];

              setStatus(user.status === '1' ? true : false);
              setSelectedUserType(user.type);
              setName(user.name);
              setLastname(user.lastname);
              setUsername(user.username);
              setFrontAuth(user.frontAuth ? user.frontAuth : []);
              setEmail(user.email);
              setPhone(user.phone);
              setPassword(user.password);
              setSelectedRoles(selectedRolesFromFrontAuth);
              setFirstResponsibleOffice(_firstResponsibleOffice);
              setSelectedLocations(_selectedLocations);
            })
            .catch((error) => { })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setSelectedUserType('user');
          setName('');
          setLastname('');
          setUsername('');
          setEmail('');
          setPhone('');
          // setPassword('');
          generateRandomPassword();
          setSelectedRoles([]);
          setSelectedLocations([]);
          setFirstResponsibleOffice(null);
        }
      })
      .catch((err) => {
        console.log(err);
        setLocations([]);
        setSelectedUserType('user');
        setName('');
        setLastname('');
        setUsername('');
        setEmail('');
        setPhone('');
        setPassword('');
        setSelectedRoles([]);
        setSelectedLocations([]);
        setFirstResponsibleOffice(null);
      });
  }, [isUserDialogOpen]);

  const handleStatusChange = () => {
    setStatus(!status);
    setStatusChanged(!statusChanged);
  };

  return (
    <Dialog open={isUserDialogOpen}>
      <DialogTitle>
        {language.commons.roles.user} {actionType === 'new' ? language.commons.add : language.commons.edit}
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            direction: 'row',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            mb: 2,
          }}
        >
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <RadioGroup
              style={{
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%',
              }}
              aria-label="user-type"
              name="user-type"
              value={selectedUserType}
              onChange={(e) => {
                if (e.target.value === 'esnaf') {
                  setSelectedRoles(['operations']);
                }
                setSelectedUserType(e.target.value);
              }}
            >
              <FormControlLabel
                value="user"
                control={<Radio />}
                label={language.commons.roles.user}
              />
              <FormControlLabel
                value="esnaf"
                control={<Radio />}
                label={language.commons.roles.tradesmen}
              />

              {parsedUser.type === "highadmin" &&
                <>
                  <FormControlLabel
                    value="admin"
                    control={<Radio />}
                    label={language.commons.roles.admin}
                  />
                  <FormControlLabel
                    value="highadmin"
                    control={<Radio />}
                    label={language.commons.roles.high_admin}
                  />  
                </>
              }

            </RadioGroup>
          </FormControl>
          {actionType === 'edit' &&
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#6B7280',
                  fontFamily: 'Inter',
                  marginRight: '5px',
                }}
              >
                {language.commons.labels.user_status}:
              </Typography>
              {status ? (
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#079455',
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.labels.active}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#D92D20',
                    fontFamily: 'Inter',
                  }}
                >
                  {language.commons.labels.passive}
                </Typography>
              )}

              <Switch
                checked={status}
                onChange={() => handleStatusChange()}
                name="status"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          }
        </Stack>

        <Select
          fullWidth
          label={language.commons.labels.auth_levels + ' *'}
          placeholder={language.commons.labels.auth_levels + ' *'}
          displayEmpty
          multiple
          disabled={selectedUserType === 'esnaf'}
          value={selectedRoles}
          onChange={(event) => {
            setSelectedRoles(event.target.value);
          }}
          input={<OutlinedInput />}
          sx={{ mb: 2 }}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <em>{language.commons.labels.auth_levels + ' *'}</em>;
            }

            return selected
              .map((value) => roles?.find((role) => role.value === value).title)
              .join(', ');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {roles?.map((x, index) => (
            <MenuItem key={index} value={x.value}>
              {x.title}
            </MenuItem>
          ))}
        </Select>

        <TextField
          label="Ad *"
          autoComplete="off"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ my: 2 }}
        />
        <TextField
          label="Soyad *"
          autoComplete="off"
          fullWidth
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="E-posta *"
          autoComplete="off"
          fullWidth
          value={email}
          onChange={
            actionType === 'new'
              ? (e) => {
                setEmail(e.target.value);
                setUsername(e.target.value);
              }
              : (e) => setEmail(e.target.value)
          }
          sx={{ mb: 2 }}
        />
        <TextField
          label={language.commons.labels.username + '*'}
          autoComplete="off"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label={language.commons.labels.phone + '*'}
          autoComplete="off"
          fullWidth
          value={phone}
          type="number"
          onChange={(e) => setPhone(e.target.value)}
          sx={{ mb: 2 }}
        />
        <div style={{ display: 'flex', position: 'relative' }}>
          <TextField
            label={language.commons.labels.password + '*'}
            autoComplete="off"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          {actionType === 'edit' && (
            <Button
              disabled={resetLoading}
              variant="contained"
              style={{
                right: 30,
                marginTop: 10,
                position: 'absolute',
              }}
              onClick={handleResetPassword}
            >
              <>{resetLoading ? language.components.AddEditUserModal.password : language.components.AddEditUserModal.pass_reset} </>
            </Button>
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: 2, height: '100%' }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {isAutocompleteVisible && (
          <>
            {' '}
            <Autocomplete
              id="grouped-demo"
              fullWidth
              value={firstResponsibleOffice}
              sx={{ mb: 2 }}
              options={options?.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                setFirstResponsibleOffice(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language.commons.labels.allowedPoints + '*'}
                  placeholder={language.commons.labels.allowedPoints + '*'}
                />
              )}
            />
            <Autocomplete
              id="grouped-demo2"
              fullWidth
              multiple
              value={selectedLocations}
              disabled={
                firstResponsibleOffice === null ||
                firstResponsibleOffice === '' ||
                firstResponsibleOffice?.length === 0
              }
              options={options?.filter(
                (option) =>
                  !selectedLocations.map((loc) => loc.id).includes(option.id) &&
                  option.id !== firstResponsibleOffice?.id
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                setSelectedLocations(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={language.components.AddEditUserModal.auth_units}
                  placeholder={language.components.AddEditUserModal.auth_units}
                />
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={buttonLoading}
          variant="contained"
          style={{}}
          onClick={handleSubmit}
        >
          <>
            {buttonLoading
              ? language.components.AddEditUserModal.process_being
              : actionType === 'edit'
                ? language.commons.finish_edit
                : language.commons.add}
          </>
        </Button>
        <Button onClick={handleCloseModal}>{language.commons.cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditUserModal;
