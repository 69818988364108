import { Backdrop, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import CustomFilter from 'src/components/filter/CustomFilter';
import ClearButton from 'src/components/shared/ClearButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import FilterButton from 'src/components/shared/FilterButton';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';
import Services from 'src/services/Services';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import api from 'src/services/BaseService';

export default function BolParaApplications() {
    const { language } = useLocalization();
    const parsedUser = JSON.parse(localStorage.getItem('user'));

    const [filterModal, setFilterModal] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [applicant, setApplicant] = useState("");
    const [campaignApplyCode, setCampaignApplyCode] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [pointName, setPointName] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [status, setStatus] = useState("");

    const [responseList, setResponseList] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedResponseID, setSelectedResponseID] = useState(null);
    const [actionType, setActionType] = useState(null);

    const [approvedCount, setApprovedCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [showPopUpError, setShowPopUpError] = useState(false);
    const [selectedReason, setSelectedReason] = useState("");

    const handleModal = () => {
        setFilterModal(true);
    };

    const handleCloseModal = () => {
        setFilterModal(false);
    };

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ""); // Sadece rakamları al
        if (numericValue.length <= 10) {
            setPhone(numericValue); // Maksimum 10 karakter sınırı
        }
    };

    let TABLE_HEAD = [
        { id: 'applicant', label: language.commons.column.applicant, format: (value, row) => { return (row.data[0].name + " " + row.data[0].lastname) }, sortBy: 'data[0].name' },
        { id: 'campaignApplyCode', label: language.commons.column.campaign_apply_code },
        { id: 'phone', label: language.commons.column.phone, format: (value, row) => { return (row.data[0].phone) }, sortBy: 'data[0].phone' },
        { id: 'companyTitle', label: language.commons.labels.brand_name },
        { id: 'title', label: language.commons.column.campaign_name },
        { id: 'branchCode', label: "Şube Kodu" },
        { id: 'poiTitle', label: language.commons.column.point_name },
        { id: 'createdDateTime', label: language.commons.column.created_date, format: (value) => formatDate(value) },
        {
            id: 'status',
            label: language.commons.status,
            format: (value) => {
                return (
                    <Box
                        component="span"
                        sx={{
                            display: 'inline-block',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            color: '#fff',
                            backgroundColor:
                                value === "1" ? 'green' :
                                    (value === "2" || value === "4") ? 'red' :
                                        'orange'
                        }}
                    >
                        {value === "1" ? language.commons.labels.approved : value === "2" ? language.commons.labels.rejected : value === "4" ? language.commons.labels.declined_old_member : language.commons.labels.awaiting_approval}
                    </Box>
                );
            }
        },
        {
            id: 'operations', label: '', format: (value, row) => {
                if (row.status === "3") {
                    return (
                        <div>
                            <Button onClick={() => openConfirmDialog(row.responseID, 1)}>{language.commons.approve}</Button>
                            <Button onClick={() => openConfirmDialog(row.responseID, 2)}>{language.commons.decline}</Button>
                        </div>
                    );
                } else {
                    return null;
                }
            }
        },
    ];

    const formatDate = (date) => {
        const formattedDate = moment.unix(date).format('DD.MM.YYYY HH:mm');
        return formattedDate;
    };

    const getResponses = () => {
        setLoading(true);
        Services.GetBolParaResponses(parsedUser.token)
            .then((res) => {
                if (res.data.status) {
                    setResponseList(res.data.result);
                    setOriginalData(res.data.result);

                    const approvedResponses = res.data.result.filter(response => response.status === "1");
                    const rejectedResponses = res.data.result.filter(response => response.status === "2" || response.status === "4");
                    const pendingResponses = res.data.result.filter(response => response.status === "3");

                    setApprovedCount(approvedResponses.length);
                    setRejectedCount(rejectedResponses.length);
                    setPendingCount(pendingResponses.length);

                    setLoading(false);
                } else {
                    toast.error("Başvuru bulunamadı.");
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const updateResponseStatus = (responseID, status) => {
        setLoading(true);
        Services.UpdateBolParaResponse(parsedUser.token, responseID, status)
            .then((res) => {
                if (res.data.status) {
                    getResponses();
                    setConfirmModalOpen(false);
                    setSelectedReason("")
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const openConfirmDialog = (responseID, actionType) => {
        setSelectedResponseID(responseID);
        setActionType(actionType);
        setConfirmModalOpen(true);
    };

    const handleConfirm = () => {
        if (selectedResponseID && actionType !== null) {
            if (actionType === 1) {
                updateResponseStatus(selectedResponseID, 1);
            }
            else {
                if (actionType === 2 && selectedReason === "cancel_sale") {
                    updateResponseStatus(selectedResponseID, 2);
                }
                else if (actionType === 2 && selectedReason === "cancel_sale_old_member") {
                    updateResponseStatus(selectedResponseID, 4);
                }
            }
        }
    };

    const handleClose = () => {
        setConfirmModalOpen(false);
        setSelectedReason("")
    };

    useEffect(() => {
        getResponses();
    }, []);

    const clearFilters = () => {
        setResponseList(originalData);
        setStartDate(null)
        setEndDate(null)
        setApplicant("")
        setCompanyName("")
        setPointName("")
        setCampaignName("")
        setStatus("")
        setCampaignApplyCode("")
        setPhone("")
    };

    const getFilteredApplicationList = () => {
        let filteredApplicationList;
        if (
            applicant ||
            campaignApplyCode ||
            phone ||
            companyName ||
            (startDate && endDate) ||
            pointName ||
            campaignName ||
            status
        ) {
            filteredApplicationList = originalData.filter((item) => {
                const applicantMatch = applicant
                    ? (item.data[0]?.name?.toLocaleLowerCase('tr') + " " + item.data[0]?.lastname?.toLocaleLowerCase('tr')).includes(applicant.toLocaleLowerCase('tr'))
                    : true;
                const companyNameMatch = companyName
                    ? item.companyTitle.toLocaleLowerCase('tr').includes(companyName.toLocaleLowerCase('tr'))
                    : true;
                const campaignApplyCodeMatch = campaignApplyCode
                    ? item.campaignApplyCode.toLocaleLowerCase('tr').includes(campaignApplyCode.toLocaleLowerCase('tr'))
                    : true;
                const phoneMatch = phone
                    ? item.data[0]?.phone.includes(phone)
                    : true;
                const pointNameMatch = pointName
                    ? item.poiTitle.toLocaleLowerCase('tr').includes(pointName.toLocaleLowerCase('tr'))
                    : true;
                const campaignNameMatch = campaignName
                    ? item.title.toLocaleLowerCase('tr').includes(campaignName.toLocaleLowerCase('tr'))
                    : true;
                const dateMatch =
                    startDate && endDate
                        ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
                        moment(startDate).format('DD-MM-YYYY') &&
                        moment.unix(item.createdDateTime).format('DD-MM-YYYY') <=
                        moment(endDate).format('DD-MM-YYYY')
                        : true;
                const statusMatch = status
                    ? item.status === status
                    : true;
                return (
                    dateMatch &&
                    applicantMatch &&
                    companyNameMatch &&
                    pointNameMatch &&
                    statusMatch &&
                    campaignNameMatch &&
                    campaignApplyCodeMatch &&
                    phoneMatch
                );
            });
        } else {
            filteredApplicationList = originalData;
        }

        setResponseList(filteredApplicationList);
    };

    function exportToExcel() {
        let temp1 = responseList.map((x, index) => {
            let a = {
                id: index + 1,
                applicant: x.data[0].name + " " + x.data[0].lastname,
                campaignApplyCode: x.campaignApplyCode,
                phone: x.data[0].phone,
                companyTitle: x.companyTitle,
                title: x.title,
                branchCode: x.branchCode,
                poiTitle: x.poiTitle,
                createdDateTime: moment(new Date(Number(x.createdDateTime))).format(
                    'DD/MM/YYYY'
                ),
                status: x.status === "1" ? "Satış Tamamlandı" : x.status === "2" ? "Satış İptal" : x.status === "4" ? "Satiş İptal Eski Üye" : "Onay Bekleyen"
            };
            return a;
        });

        api
            .post('excel/export', {
                type: 'excel',
                content: temp1,
                header: {
                    ID: 'number',
                    'Başvuran Kişi': 'string',
                    'Başvuru Kodu': 'string',
                    'Telefon': 'string',
                    'Marka Adı': 'string',
                    'Kampanya Adı': 'string',
                    'Şube Kodu': 'string',
                    'Nokta Adı': 'string',
                    'Oluşturulma Tarihi': 'string',
                    'Durum': 'string',
                },
                sheetTitle: 'Bol Para Başvurular',
            })
            .then((response) => {
                console.log('excel: ', response.data);
                if (response.data.status) {
                    try {
                        const newWindow = window.open(response.data.result, '_blank');
                        if (
                            !newWindow ||
                            newWindow.closed ||
                            typeof newWindow.closed == 'undefined'
                        ) {
                            setShowPopUpError(true);
                            throw new Error(
                                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
                            );
                        }
                    } catch (e) {
                        setShowPopUpError(true);
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    return (
        <>
            <Helmet>
                <title>{language.bol_para_applications.titles.site_title}</title>
            </Helmet>

            <Stack style={{ paddingInline: '25px' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        {language.bol_para_applications.titles.title}
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent={isMobile ? 'space-between' : 'end'}
                    mb={5}
                    paddingX={2}
                >
                    <ExportToExcelButton
                        setShowPopUpError={setShowPopUpError}
                        showPopUpError={showPopUpError}
                        onClick={() => exportToExcel()}
                    />
                    {isMobile ? (
                        <FilterViewButton variant="contained" onClick={handleModal} />
                    ) : null}
                </Stack>
                <Card>
                    {loading ? (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (
                        <>
                            <Stack direction="row" spacing={3} mb={3} sx={{ marginLeft: '15px', marginTop: '15px' }}>

                                <Typography variant="body1" sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div style={{ width: '15px', height: '15px', backgroundColor: 'green', marginRight: '10px' }} />{language.bol_para_applications.titles.approved}: {approvedCount}</Typography>
                                <Typography variant="body1" sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div style={{ width: '15px', height: '15px', backgroundColor: 'red', marginRight: '10px' }} />{language.bol_para_applications.titles.declined}/{language.commons.labels.declined_old_member}: {rejectedCount}</Typography>
                                <Typography variant="body1" sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>  <div style={{ width: '15px', height: '15px', backgroundColor: 'orange', marginRight: '10px' }} />{language.bol_para_applications.titles.awaiting_approval}: {pendingCount}</Typography>
                            </Stack>

                            <CustomFilter
                                modalType={'drawer'}
                                filterModal={filterModal}
                                handleCloseModal={handleCloseModal}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    paddingY={2}
                                    paddingX={1}
                                >
                                    <Stack
                                        mr={1}
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        sx={{ marginBottom: 2, flex: 1 }}
                                    >
                                        <Stack
                                            direction={isMobile ? 'column' : 'row'}
                                            spacing={2}
                                            alignItems={'center'}
                                        >
                                            <FormControl sx={isMobile ? { width: '100%' } : null}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        label={language.commons.labels.start_date}
                                                        value={startDate}
                                                        onChange={(newValue) => setStartDate(newValue)}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <FormControl sx={isMobile ? { width: '100%' } : null}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        label={language.commons.labels.end_date}
                                                        value={endDate}
                                                        onChange={(newValue) => setEndDate(newValue)}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Stack>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Başvuran Kişi"
                                                variant="outlined"
                                                value={applicant}
                                                onChange={(e) => setApplicant(e.target.value)}
                                                sx={{ mt: 2, flex: 1 }}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <TextField
                                                label="Telefon"
                                                variant="outlined"
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                sx={{ mt: 2, flex: 1 }}
                                                inputProps={{ maxLength: 10 }}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <TextField
                                                label="Başvuru Kodu"
                                                variant="outlined"
                                                value={campaignApplyCode}
                                                onChange={(e) => setCampaignApplyCode(e.target.value)}
                                                sx={{ mt: 2, flex: 1 }}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <TextField
                                                label="Marka Adı"
                                                variant="outlined"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                sx={{ mt: 2, flex: 1 }}
                                            />
                                        </FormControl>


                                        <FormControl fullWidth>
                                            <TextField
                                                label="Nokta Adı"
                                                variant="outlined"
                                                value={pointName}
                                                onChange={(e) => setPointName(e.target.value)}
                                                sx={{ mt: 2, flex: 1 }}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth>

                                            <TextField
                                                label="Kampanya Adı"
                                                variant="outlined"
                                                value={campaignName}
                                                onChange={(e) => setCampaignName(e.target.value)}
                                                sx={{ mt: 2, flex: 1 }}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            <InputLabel id="type-filter-mobile">Durum</InputLabel>
                                            <Select
                                                labelId="type-filter-mobile"
                                                placeholder="Durum"
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                label="Durum"
                                            >
                                                <MenuItem value="1">{language.commons.labels.approved}</MenuItem>
                                                <MenuItem value="2">{language.commons.labels.rejected}</MenuItem>
                                                <MenuItem value="3">{language.commons.labels.awaiting_approval}</MenuItem>
                                                <MenuItem value="4">{language.commons.labels.declined_old_member}</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Stack>
                                </Stack>

                                <Divider sx={{ width: '100%', paddingTop: '10px' }} />

                                <Stack
                                    mr={2}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    paddingY={2}
                                    paddingX={1}
                                >

                                    <ClearButton onClick={() => clearFilters()} />
                                    <FilterButton onClick={() => getFilteredApplicationList()} />
                                </Stack>
                            </CustomFilter>

                            <DynamicTable
                                data={responseList}
                                headers={TABLE_HEAD}
                                isOrdered={false}
                                isMobile={isMobile}
                                handleModal={handleModal}
                                handleCloseModal={handleCloseModal}
                                initialOrder={"createdDateTime"}
                                initalOrderDirection={"desc"}
                            />
                        </>

                    )}
                </Card>
            </Stack>

            {/* Confirmation Dialog */}
            <Dialog open={confirmModalOpen} onClose={handleClose}>
                <DialogTitle>{actionType === 1 ? language.commons.approve : language.commons.decline}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {actionType === 1 ? language.bol_para_applications.titles.approve_message : language.bol_para_applications.titles.reject_message}
                        <p>{actionType !== 1 && language.bol_para_applications.titles.select_reject_type}</p>
                    </DialogContentText>

                    {/* Eğer actionType 1 değilse RadioGroup göster */}
                    {actionType !== 1 && (
                        <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                            <RadioGroup value={selectedReason} onChange={handleReasonChange}>
                                <FormControlLabel value="cancel_sale" control={<Radio />} label="Satış İptal" />
                                <FormControlLabel value="cancel_sale_old_member" control={<Radio />} label="Satış İptal Eski Üye" />
                            </RadioGroup>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{language.commons.no}</Button>
                    <Button onClick={() => handleConfirm(selectedReason)} color="primary">
                        {language.commons.yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
